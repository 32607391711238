import { useTranslation } from "next-i18next";
import { useState } from "react";

interface Tab {
  id: string;
  name: string;
}

interface Props {
  activeTab: string;
  handleTabClick: (tabId: string) => void;
}

function LoginTab({ activeTab, handleTabClick }: Props) {
  const { t: translate } = useTranslation();
  const [tabs] = useState<Tab[]>([
    { id: "phone", name: translate("general.Phone number") },
    { id: "email", name: translate("general.Email") },
  ]);

  return (
    <ul className="flex p-2 px-8 mb-6 text-sm text-center rounded-full bg-neutral">
      {tabs.map(({ id, name }) => (
        <li
          key={id}
          onClick={() => handleTabClick(id)}
          className={`rounded-full cursor-pointer w-32 ${
            activeTab === id ? "activeTab bg-white text-primary" : "p-3"
          }`}
        >
          <h1>{name}</h1>
        </li>
      ))}
    </ul>
  );
}

export default LoginTab;
