import { useState } from 'react';
import parsePhoneNumber, { PhoneNumber } from 'libphonenumber-js';

interface UsePhoneNumberValidationReturn {
  isValid: boolean;
  parsedPhoneNumber: PhoneNumber | null;
  validatePhoneNumber: (phoneNumber: string) => void;
}

function usePhoneNumberValidation(): UsePhoneNumberValidationReturn {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [parsedPhoneNumber, setParsedPhoneNumber] = useState<PhoneNumber | null>(null);

  const validatePhoneNumber = (phoneNumber: string) => {
    const parsedNumber = parsePhoneNumber(phoneNumber);
    const isValidNumber = parsedNumber ? parsedNumber.isValid() : false;
    setIsValid(isValidNumber);
    setParsedPhoneNumber(parsedNumber as PhoneNumber);
  };

  return { isValid, parsedPhoneNumber, validatePhoneNumber };
}

export default usePhoneNumberValidation;
