import usePhoneNumberValidation from "hooks/usePhoneNumberValidation";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiHide, BiShow } from "react-icons/bi";
import PhoneInput from "react-phone-input-2";
import { useUserStore } from "store/useUserStore";

const countryCodes: CountryCode = require("utils/shared/country-codes.json");
interface CountryCode {
  [key: string]: string;
}

interface loginForm {
  handleLogin: (data: any, phoneCode: string | null) => void;
  isLoading: boolean;
}

type PhoneNumberValidationProps = {
  phoneCodeState: string;
  validatePhoneNumber: (mobileNumber: string) => void;
};

function LoginFormPhone({ handleLogin, isLoading }: loginForm) {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [phoneCodeState, setPhoneCodeState] = useState<null | string>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { nationalityCode }: any = useUserStore();
  const { t: translate } = useTranslation();
  const { locale } = useRouter();

  const { isValid, parsedPhoneNumber, validatePhoneNumber } =
    usePhoneNumberValidation();

  const handlePhoneNumberValidation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let { value } = event.target;
    // Remove leading zeros if present
    if (value.startsWith("0")) {
      value = value.replace(/^0+/, "");
    }
    const mobileNumber = `+${phoneCodeState}${value}`;
    validatePhoneNumber(mobileNumber);
    setValue("phone", value)
   };

  useEffect(() => {
    setPhoneCodeState(
      countryCodes[(nationalityCode.toUpperCase() as string) ?? "SA"]
    );
  }, []);
  return (
    <div className="w-full mb-5 form-control">
      <form
        onSubmit={handleSubmit((data) => {
          if (!isValid) return setError("phone", { type: "invalid" });
          handleLogin(data, phoneCodeState as string);
        })}
      >
        <div className="flex gap-2">
          <PhoneInput
            country={nationalityCode?.toLowerCase() ?? "sa"}
            containerStyle={{ width: "40%" }}
            inputStyle={{
              backgroundColor: "#F2F7FB",
              width: "100%",
              height: "85%",
              border: "none",
              padding: locale === "ar" ? "5px" : "",
            }}
            buttonStyle={{
              direction: "ltr",
              backgroundColor: "#F2F7FB",
              border: "none",
              height: "85%",
            }}
            inputProps={{
              readOnly: true,
            }}
            buttonClass="text-xs"
            onChange={(phone) => setPhoneCodeState(phone)}
            enableSearch={true}
            searchPlaceholder="Search for a country"
          />
          <input
            {...register("phone", {
              required: true,
              pattern: /^\d+$/,
            })}
            required
            type="text"
            style={{ backgroundColor: "#F2F7FB" }}
            className="w-full mb-2 text-xs input text-slate-800 rounded-xl [&::-webkit-inner-spin-button]:appearance-none [appearance:textfield]"
            placeholder={translate("general.Phone number") as string}
            onChange={handlePhoneNumberValidation}
          />
        </div>
        {errors.phone && (
          <p className="mb-2 text-xs text-error">
            {translate(
              errors.phone.type === "required"
                ? "errors.Phone Required"
                : "errors.Phone Error"
            )}
          </p>
        )}
        <div className="relative">
          <input
            {...register("password", {
              required: true,
            })}
            required
            type={showPassword ? "text" : "password"}
            name="password"
            style={{ backgroundColor: "#F2F7FB" }}
            className="w-full text-xs input text-slate-800 rounded-xl"
            placeholder={translate("general.Password") as string}
          />
          {!showPassword ? (
            <BiShow
              onClick={() => setShowPassword(true)}
              className={`absolute text-xl text-gray-400 rounded-none cursor-pointer top-3
              ${locale === "en" ? "right-2" : "left-2"}`}
            />
          ) : (
            <BiHide
              onClick={() => setShowPassword(false)}
              className={`absolute text-xl text-gray-400 rounded-none cursor-pointer top-3
              ${locale === "en" ? "right-2" : "left-2"}`}
            />
          )}
        </div>
        {errors.password && (
          <p className="mt-2 text-xs text-error">
            {translate("errors.Password Error")}
          </p>
        )}
        <button
          className={`w-full mt-6 text-white normal-case rounded-full btn btn-primary ${
            isLoading && "loading"
          } `}
        >
          {translate("user.Login")}
        </button>
      </form>
    </div>
  );
}

export default LoginFormPhone;
