import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import paths from "utils/configs/paths";

import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useUserStore } from "store/useUserStore";

import Layout from "components/layout/defaultLayout";
import LoginFormEmail from "components/login/loginFormEmail";
import LoginFormPhone from "components/login/loginFormPhone";
import LoginProviders from "components/login/LoginProviders";
import LoginTab from "components/login/loginTab";

import { useTranslation } from "next-i18next";

import type {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
} from "next";
import { logoUrl } from "constant/main";

// import { getProviders } from "next-auth/react";
// import authOptions from "../api/auth/___[...nextauth]";
// import { getServerSession } from "next-auth/next";
export default function Login({}: InferGetServerSidePropsType<
  typeof getServerSideProps
>) {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("email");
  const [loginError, setLoginError] = useState({ message: "", isError: false });
  const [isEmailForm, setIsEmailForm] = useState(true);
  const { push, locale } = useRouter();
  const { loginUser }: any = useUserStore();
  const { t: translate } = useTranslation();

  useEffect(() => {
    const userStore = JSON.parse(localStorage.getItem("UserStore") as string);
    if (userStore && userStore.state && userStore.state.token) {
      push("/");
    }
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedTab = sessionStorage.getItem("activeTab");
      if (storedTab && (storedTab === "email" || storedTab === "phone")) {
        setActiveTab(storedTab);
      }
    }
  }, []);

  // Function to handle tab click
  const handleTabClick = (tabId: any) => {
    setActiveTab(tabId); // Update active tab state
    sessionStorage.setItem("activeTab", tabId); // Store active tab in session Storage
  };
  const handleLogin = async (data: any, phoneCode: string | null) => {
    setIsLoading(true);
    setLoginError({ ...loginError, isError: false });

    const response = await loginUser(data, phoneCode);
    if (response.isAuth) {
      if (sessionStorage.getItem("redirectPath")) {
        push(sessionStorage.getItem("redirectPath")!);
        sessionStorage.removeItem("redirectPath");
        return;
      }
      push({ pathname: paths.home });
    } else {
      setLoginError({ message: response.error, isError: true });
      setIsLoading(false);
    }
  };
  return (
    <Layout>
      <Head>
        <title>Modeef | Login</title>
      </Head>

      <main className="flex items-center justify-center pb-28 mt-13">
        <div className="flex flex-col items-center h-screen lg:w-1/3 md:w-2/3 ">
          <Image
            width={250}
            alt="Logo"
            height={100}
            src={locale === "en" ? logoUrl.en : logoUrl.ar}
            className="hidden mb-10 md:block p-4"
          />

          <LoginTab activeTab={activeTab} handleTabClick={handleTabClick} />
          {activeTab === "email" ? (
            <LoginFormEmail handleLogin={handleLogin} isLoading={isLoading} />
          ) : (
            <LoginFormPhone handleLogin={handleLogin} isLoading={isLoading} />
          )}

          {loginError.isError && (
            <p className="text-sm text-error">{loginError.message}</p>
          )}
          <Link
            href={paths.login.forgotPassword}
            className="text-sm link-hover text-primary "
          >
            <h3>{translate("user.Forgot your password?")}</h3>
          </Link>

          <LoginProviders />
          <p className="text-xs text-slate-400 flex flex-col items-center">
            {translate("user.Dont have an account")}
            <span className=" text-sm text-white bg-gradient-to-b from-[#600E3D] to-[#9E1F63] px-4 py-2 rounded-md inline-block animate-pulse">
              <Link href="/register" className="text-base link-hover">
                {translate("user.Create an account")}
              </Link>
            </span>
          </p>
        </div>
      </main>
    </Layout>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  // const providers = await getProviders();
  //const session = await getServerSession(context, authOptions);
  //
  return {
    props: {
      // providers: providers ?? [],
      //   session: session ?? null,
      ...(await serverSideTranslations(context.locale!)),
    },
  };
}
