import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BiHide, BiShow } from "react-icons/bi";

interface loginForm {
  handleLogin: (data: any, phoneCode: string | null) => void;
  isLoading: boolean;
}

function LoginFormEmail({ handleLogin, isLoading }: loginForm) {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const { t: translate } = useTranslation();
  const { locale } = useRouter();
  return (
    <div className="w-full mb-5 form-control">
      <form
        className="form-control"
        onSubmit={handleSubmit((data) => {
          handleLogin(data, null);
        })}
      >
        <input
          {...register("email", {
            required: translate("errors.Please enter your email") as string,
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          })}
          type="email"
          required
          style={{ backgroundColor: "#F2F7FB" }}
          className="w-full mb-2 text-xs input text-slate-800 rounded-xl"
          placeholder={translate("general.Email") as string}
        />
        {errors.email && (
          <p className="mb-2 text-xs text-error">
            {translate(
              errors.email.type === "required"
                ? "errors.Email Required"
                : "errors.Email Error"
            )}
          </p>
        )}
        <div className="relative">
          <input
            {...register("password", {
              required: "Password Error",
            })}
            type={showPassword ? "text" : "password"}
            name="password"
            style={{ backgroundColor: "#F2F7FB" }}
            className="w-full text-xs input text-slate-800 rounded-xl"
            placeholder={translate("general.Password") as string}
          />
          {!showPassword ? (
            <BiShow
              onClick={() => setShowPassword(true)}
              className={`absolute text-xl text-gray-400 rounded-none cursor-pointer top-3
              ${locale === "en" ? "right-2" : "left-2"}`}
            />
          ) : (
            <BiHide
              onClick={() => setShowPassword(false)}
              className={`absolute text-xl text-gray-400 rounded-none cursor-pointer top-3
              ${locale === "en" ? "right-2" : "left-2"}`}
            />
          )}
        </div>
        {errors.password && (
          <p className="mt-2 text-xs text-error">
            {translate("errors.Password Error")}
          </p>
        )}
        <button
          className={`w-full mt-6 text-white normal-case rounded-full btn btn-primary ${
            isLoading && "loading"
          } `}
        >
          {translate("user.Login") as string}
        </button>
      </form>
    </div>
  );
}

export default LoginFormEmail;
