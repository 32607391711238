import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaGoogle } from "react-icons/fa";
import { useUserStore } from "store/useUserStore";
import { signInwithProvider } from "../../firebase/firebase";
// import { useSession, signIn, signOut, getSession } from "next-auth/react";

const providers = {
  facebook: {
    id: "facebook",
    name: "Facebook",
    type: "oauth",
  },
  google: {
    id: "google",
    name: "Google",
    type: "oauth",
  },
  twitter: {
    id: "twitter",
    name: "Twitter",
    type: "oauth",
  },
};
function LoginProviders({}: any) {
  const { t: translate } = useTranslation();
  const { push } = useRouter();
  const { loginWithProvider }: any = useUserStore();
  const [isLoginError, setIsLoginError] = useState<any>({
    status: false,
    message: null,
  });

  // const { data: session } = useSession();

  const handleLogin = async (provider: string) => {
    setIsLoginError({ status: false, message: null });
    const authProviderResponse: any = await signInwithProvider(provider);

    if (authProviderResponse && authProviderResponse.data) {
      const { localId, firstName, lastName, email, photoUrl } =
        authProviderResponse.data;
      const loginResponse = await loginWithProvider({
        localId,
        firstName,
        lastName,
        email,
        photoUrl,
        provider: provider === "google" ? "gmail" : provider,
      });
      if (loginResponse.isAuth) push("/");
      else
        setIsLoginError({
          status: true,
          message: () => {
            for (let key in loginResponse.error.errors) {
              return loginResponse.error.errors[key];
            }
          },
        });
    }
  };

  return (
    <>
      <div className="mt-6 text-xs divider">
        <h3>{translate("user.Or login with") as string}</h3>
      </div>
      {isLoginError.status && (
        <p className="my-2 text-sm text-error">
          {typeof isLoginError.message === "function" && isLoginError.message()}
        </p>
      )}
      {isLoginError.status && (
        <p className="my-2 text-sm text-error">{isLoginError.message}</p>
      )}
      <div className="flex gap-3 mb-4 justify-center">
        {providers &&
          Object.values(providers).map((provider: any) => (
            <button
              key={provider.name}
              className="gap-2 font-thin normal-case border-gray-400 border-solid btn-outline btn btn-primary "
              onClick={() => handleLogin(provider.id)}
            >
              {provider.id === "facebook" && <FaFacebookF />}
              {provider.id === "twitter" && <BsTwitter />}
              {provider.id === "google" && <FaGoogle />}

              <h3 className="text-slate-700 hover:text-white ">
                {provider.name}
              </h3>
            </button>
          ))}
      </div>
    </>
  );
}

export default LoginProviders;
