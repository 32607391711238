import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp, getApp, getApps } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";
import { logError } from "utils/helpers/loggers";
import { logInfo } from "utils/helpers/loggers";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = !getApps().length
  ? initializeApp(firebaseConfig)
  : getApp();

export const signInwithProvider = async (providerName) => {
  let provider;
  switch (providerName) {
    case "google":
      provider = new GoogleAuthProvider();
      break;
    case "facebook":
      provider = new FacebookAuthProvider();
      break;
    case "twitter":
      provider = new TwitterAuthProvider();
      break;
    default:
      throw new Error(`Unsupported provider: ${providerName}`);
  }

  const auth = getAuth(firebaseApp);
  try {
    const result = await signInWithPopup(auth, provider);
    return { data: result._tokenResponse };
  } catch (error) {
    logError(`Error signing in with ${providerName}: ${error.message}`);
    return { error: error.message }; 
  }
};

export const firebaseDeviceToken = async () => {
  const messaging = getMessaging(firebaseApp);

  try {
    const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
      serviceWorkerRegistration: registration,
    });

    if (currentToken) {
      return { data: currentToken };
    } else {
      logInfo("No registration token available. Request permission to generate one.");
      return { error: "No registration token available." };
    }
  } catch (error) {
    logError(`Error getting Firebase messaging token: ${error.message}`);
    return { error: error.message }; 
  }
};
